import type { APIError } from '~/interfaces/api/main';
import type { AuthUserData, AuthUserMeta } from '~/interfaces/users/main';

export const useUsersStore = defineStore('UsersStore', () => {
  const authUserData = ref<AuthUserData | null>(null);
  const authUserMeta = ref<AuthUserMeta | null>(null);
  const loading = ref<boolean>(false);
  const error = ref<APIError[] | null>(null);

  async function fetchMe () {
    loading.value = true;
    try {
      const response = await useAuthFetch('me');
      ({ data: authUserData.value } = response.data.value);
      ({ meta: authUserMeta.value } = response.data.value);
    } catch (error) {
      error.value = error;
      if (error.value?.status === 401) {
        window.location.href = `${config.public.ARC_URL}/login`;
      }
    } finally {
      loading.value = false;
    }
  }

  function userHasPermission (permissionName: string, scope = null) {
    return scope
      ? scope.permissions?.includes(permissionName)
      : authUserMeta.value?.permissions?.includes(permissionName);
  }

  return {
    authUserData,
    authUserMeta,
    loading,
    error,
    fetchMe,
    userHasPermission,
  };
});
