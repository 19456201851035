<script setup lang="ts">
import { useRoute } from 'vue-router';

const route = useRoute();
const pathPieces = ref([]);
const home = ref({
  label: '',
  icon: 'pi pi-home',
  route: '/',
});

onMounted(() => {
  // At component loading
  if (route?.fullPath?.split('?')[0]?.split('/')?.filter(p => p !== '').reverse()?.length > 0) {
    pathPieces.value = route.fullPath
      .split('?')[0] // Take the path without query parameters
      .split('/') // Split by "/"
      .filter(p => p !== '') // Filter out empty strings
      .map(piece => ({ label: piece })); // Map each piece to an object with label property
  } else {
    pathPieces.value = [];
  }
});

const computedBreadCrumbName = computed(() => {
  return (pathPart: string) => {
    let computedName = '';
    if (pathPart) {
      pathPart?.split('_').forEach((pp) => {
        if (computedName !== '') { computedName += ' '; }
        computedName += pp[0]?.toUpperCase() + pp?.slice(1);
      });
    }
    return computedName;
  };
});

// When route is updated
watch(() => route.fullPath, (fullPath) => {
  if (fullPath?.split('?')[0]?.split('/')?.filter(p => p !== '').reverse()?.length > 0) {
    pathPieces.value = fullPath
      .split('?')[0] // Take the path without query parameters
      .split('/') // Split by "/"
      .filter(p => p !== '') // Filter out empty strings
      .map(piece => ({ label: piece })); // Map each piece to an object with label property
  } else {
    pathPieces.value = [];
  }
});
</script>

<template>
  <div>
    <Breadcrumb
      :home="home"
      :model="pathPieces"
    >
      <template #item="{ item, props }">
        <!-- TODO : Uncomment when breadcrumb routing is ready (home, list, ...) -->
        <!-- <router-link v-if="item.route" v-slot="{ href, navigate }" :to="item.route" custom>
          <a :href="href" v-bind="props.action" @click="navigate">
            <span :class="[item.icon, 'text-color']" />
            <span class="text-primary font-semibold">{{ computedBreadCrumbName(item.label) }}</span>
          </a>
        </router-link>
        <a v-else :href="item.url" :target="item.target" v-bind="props.action">
          <span class="text-surface-700 dark:text-surface-0">{{ computedBreadCrumbName(item.label) }}</span>
        </a> -->
        <a
          :href="item.url"
          :target="item.target"
          v-bind="props.action"
          class="cursor-pointer"
        >
          <span
            v-if="item.icon"
            :class="[item.icon, 'text-color']"
          />
          <span class="text-surface-700 dark:text-surface-0">{{ computedBreadCrumbName(item.label) }}</span>
        </a>
      </template>
      <template #separator>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="6.81"
          height="21"
          viewBox="0 0 12 37"
          fill="none"
        >
          <path
            d="M1 1L11 18.5L1 36"
            stroke="#E0E0E0"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </template>
    </Breadcrumb>
  </div>
</template>

<style lang="scss" scoped>
:deep(.p-breadcrumb) {
  height: 21px;
  padding: 0 0 0 1rem;
  .p-breadcrumb-list {
    height: 100%;
  }
  .p-breadcrumb-item-icon {
    color: red;
  }
}
</style>
