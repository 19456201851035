import {
  type UseTimeAgoMessages,
  type UseTimeAgoReturn,
  type UseTimeAgoUnitNamesDefault,
  useTimeAgo,
} from '@vueuse/core';

export default function useTranslatedTimeAgo (...params: Parameters<typeof useTimeAgo>): UseTimeAgoReturn<false> {
  const [date] = params;
  const messages: UseTimeAgoMessages<UseTimeAgoUnitNamesDefault> = {
    justNow: 'à l\'instant',
    past: (n: string) => n.match(/\d/) ? `il y a ${n}` : n,
    future: (n: string) => n.match(/\d/) ? `dans ${n}` : n,
    month: (n: number, past: boolean) => n === 1 ? (past ? 'le mois dernier' : 'le mois prochain') : `${n} mois`,
    year: (n: number, past: boolean) => n === 1 ? (past ? 'l\'année dernière' : 'l\'année prochaine') : `${n} année${n > 1 ? 's' : ''}`,
    day: (n: number, past: boolean) => n === 1 ? (past ? 'hier' : 'demain') : `${n} jour${n > 1 ? 's' : ''}`,
    week: (n: number, past: boolean) => n === 1 ? (past ? 'la semaine dernière' : 'le mois dernier') : `${n} semaine${n > 1 ? 's' : ''}`,
    hour: (n: number) => `${n} heure${n > 1 ? 's' : ''}`,
    minute: (n: number) => `${n} minute${n > 1 ? 's' : ''}`,
    second: (n: number) => `${n} seconde${n > 1 ? 's' : ''}`,
    invalid: 'date invalide',
  };

  return useTimeAgo(date, {
    fullDateFormatter: (date: Date) => date.toLocaleString(),
    messages,
  });
}
