<script setup>
import { useToast } from 'primevue/usetoast';
import useTranslatedTimeAgo from '~/composables/useTranslatedTimeAgo';

const emit = defineEmits(['refresh']);
const props = defineProps({
  notification: {
    type: Object,
    required: true,
  },
});

const toast = useToast();
const timeAgo = useTranslatedTimeAgo(props.notification.createdAt);

const loading = ref(false);

const readableDate = computed(() => new Date(props.notification.createdAt).toLocaleString());

async function setNotificationStatus (props, read) {
  loading.value = true;

  let response;

  try {
    response = await useAuthFetch(`/notifications/${props.notification.id}`, {
      method: 'PATCH',
      body: { read },
    });

    if (response.error.value) {
      return toast.add({
        severity: 'error',
        summary: 'Erreur lors de la sauvegarde',
      });
    }
  } finally {
    loading.value = false;
    emit('refresh', response.data.value.data);
  }
}
</script>

<template>
  <NuxtLink
    :href="notification.link"
    class="flex px-6 justify-between items-center gap-6 py-4"
    :class="{ 'notif-card': notification.link }"
    style="border-bottom: 1px solid lightgray; color: inherit; text-decoration: none;"
  >
    <div
      class="flex flex-col gap-1"
      :class="{
        'opacity-30': notification.readAt,
      }"
    >
      <span
        class="text-sm"
        :title="readableDate"
      >{{ timeAgo }}</span>
      <span class="font-semibold">{{ notification.title }}</span>
      <span class="text-sm">{{ notification.content }}</span>
    </div>

    <Button
      v-tooltip.top="notification.readAt ? 'Marquer comme non lue' : 'Marquer comme lue'"
      :icon="notification.readAt ? 'pi pi-undo' : 'pi pi-check'"
      severity="secondary"
      style="aspect-ratio: 1;"
      class="flex-none"
      :loading="loading"
      @click.prevent="setNotificationStatus(props, !notification.readAt)"
    />
  </NuxtLink>
</template>

<style scoped>
.notif-card:hover {
  transition: all 0.2s ease;
  background-color: var(--surface-hover);
}
</style>
