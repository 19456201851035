import PrimeVue from 'primevue/config';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import Select from 'primevue/select';
import Divider from 'primevue/divider';
import AutoComplete from 'primevue/autocomplete';
import Checkbox from 'primevue/checkbox';
import Dialog from 'primevue/dialog';
import InputMask from 'primevue/inputmask';
import ProgressBar from 'primevue/progressbar';
import ProgressSpinner from 'primevue/progressspinner';
import Ripple from 'primevue/ripple';
import StyleClass from 'primevue/styleclass';
import OverlayBadge from 'primevue/overlaybadge';
import Tag from 'primevue/tag';
import Skeleton from 'primevue/skeleton';
import Avatar from 'primevue/avatar';
import AvatarGroup from 'primevue/avatargroup';
import Menubar from 'primevue/menubar';
import Menu from 'primevue/menu';
import Splitter from 'primevue/splitter';
import SplitterPanel from 'primevue/splitterpanel';
import TreeTable from 'primevue/treetable';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup'; // optional for column grouping
import Tooltip from 'primevue/tooltip';
import Row from 'primevue/row'; // optional for row
import ToastService from 'primevue/toastservice';
import Toast from 'primevue/toast';
import MultiSelect from 'primevue/multiselect';
import FileUpload from 'primevue/fileupload';
import DynamicDialog from 'primevue/dynamicdialog';
import DialogService from 'primevue/dialogservice';
import Card from 'primevue/card';
import ToggleSwitch from 'primevue/toggleswitch';
import ConfirmDialog from 'primevue/confirmdialog';
import ConfirmationService from 'primevue/confirmationservice';
import Slider from 'primevue/slider';
import RadioButton from 'primevue/radiobutton';
import DatePicker from 'primevue/datepicker';
import Tabs from 'primevue/tabs';
import TabList from 'primevue/tablist';
import Tab from 'primevue/tab';
import TabPanel from 'primevue/tabpanel';
import Textarea from 'primevue/textarea';
import ContextMenu from 'primevue/contextmenu';
import ToggleButton from 'primevue/togglebutton';
import Panel from 'primevue/panel';
import Popover from 'primevue/popover';
import Paginator from 'primevue/paginator';
import Inplace from 'primevue/inplace';
import Message from 'primevue/message';
import InputGroup from 'primevue/inputgroup';
import InputGroupAddon from 'primevue/inputgroupaddon';
import IftaLabel from 'primevue/iftalabel';

import TabPanels from 'primevue/tabpanels';
import { defineNuxtPlugin } from '#app';

export default defineNuxtPlugin((nuxtApp) => {
  // services
  nuxtApp.vueApp.use(PrimeVue, { ripple: true });
  nuxtApp.vueApp.use(ToastService);
  nuxtApp.vueApp.use(DialogService);
  nuxtApp.vueApp.use(ConfirmationService);

  // directives
  nuxtApp.vueApp.directive('ripple', Ripple);
  nuxtApp.vueApp.directive('styleclass', StyleClass);
  nuxtApp.vueApp.directive('badge', OverlayBadge);
  nuxtApp.vueApp.directive('Tooltip', Tooltip);

  // components
  nuxtApp.vueApp.component('Panel', Panel);
  nuxtApp.vueApp.component('Button', Button);
  nuxtApp.vueApp.component('ContextMenu', ContextMenu);
  nuxtApp.vueApp.component('InputText', InputText);
  nuxtApp.vueApp.component('InputNumber', InputNumber);
  nuxtApp.vueApp.component('Select', Select);
  nuxtApp.vueApp.component('Divider', Divider);
  nuxtApp.vueApp.component('AutoComplete', AutoComplete);
  nuxtApp.vueApp.component('Checkbox', Checkbox);
  nuxtApp.vueApp.component('Dialog', Dialog);
  nuxtApp.vueApp.component('InputMask', InputMask);
  nuxtApp.vueApp.component('ProgressBar', ProgressBar);
  nuxtApp.vueApp.component('ProgressSpinner', ProgressSpinner);
  nuxtApp.vueApp.component('Tag', Tag);
  nuxtApp.vueApp.component('Skeleton', Skeleton);
  nuxtApp.vueApp.component('Avatar', Avatar);
  nuxtApp.vueApp.component('AvatarGroup', AvatarGroup);
  nuxtApp.vueApp.component('Menu', Menu);
  nuxtApp.vueApp.component('Menubar', Menubar);
  nuxtApp.vueApp.component('Splitter', Splitter);
  nuxtApp.vueApp.component('SplitterPanel', SplitterPanel);
  nuxtApp.vueApp.component('TreeTable', TreeTable);
  nuxtApp.vueApp.component('DataTable', DataTable);
  nuxtApp.vueApp.component('Column', Column);
  nuxtApp.vueApp.component('ColumnGroup', ColumnGroup);
  nuxtApp.vueApp.component('Row', Row);
  nuxtApp.vueApp.component('Toast', Toast);
  nuxtApp.vueApp.component('MultiSelect', MultiSelect);
  nuxtApp.vueApp.component('FileUpload', FileUpload);
  nuxtApp.vueApp.component('DynamicDialog', DynamicDialog);
  nuxtApp.vueApp.component('Card', Card);
  nuxtApp.vueApp.component('ToggleSwitch', ToggleSwitch);
  nuxtApp.vueApp.component('ConfirmDialog', ConfirmDialog);
  nuxtApp.vueApp.component('Slider', Slider);
  nuxtApp.vueApp.component('RadioButton', RadioButton);
  nuxtApp.vueApp.component('DatePicker', DatePicker);
  nuxtApp.vueApp.component('Tabs', Tabs);
  nuxtApp.vueApp.component('TabPanel', TabPanel);
  nuxtApp.vueApp.component('Textarea', Textarea);
  nuxtApp.vueApp.component('ToggleButton', ToggleButton);
  nuxtApp.vueApp.component('Popover', Popover);
  nuxtApp.vueApp.component('Paginator', Paginator);
  nuxtApp.vueApp.component('Inplace', Inplace);
  nuxtApp.vueApp.component('Message', Message);
  nuxtApp.vueApp.component('InputGroup', InputGroup);
  nuxtApp.vueApp.component('InputGroupAddon', InputGroupAddon);
  nuxtApp.vueApp.component('TabList', TabList);
  nuxtApp.vueApp.component('Tab', Tab);
  nuxtApp.vueApp.component('IftaLabel', IftaLabel);
  nuxtApp.vueApp.component('Tabs', Tabs);
  nuxtApp.vueApp.component('TabPanels', TabPanels);
  nuxtApp.vueApp.component('TabPanel', TabPanel);
});
