import { useStore } from '@/stores/store';

export default defineNuxtRouteMiddleware((_to) => {
  const store = useStore();
  const options = {
    secure: !['dev', 'loc'].includes(useRuntimeConfig().public.APP_ENV),
    sameSite: true,
    maxAge: 8640,
  };
  const authCookie = useCookie('auth_token', options);

  if (_to.query.token) {
    authCookie.value = <string>_to.query.token;
    store.token = _to.query.token;
  }

  if (store.token === null && authCookie.value) {
    store.token = authCookie.value;
  }
});
