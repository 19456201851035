<template>
  <div>
    <div key="app">
      <Head>
        <Title>
          ARC v2
        </Title>
      </Head>
      <NuxtLayout>
        <NuxtPage v-if="authUserData" />
        <ClientOnly>
          <Toast />
        </ClientOnly>
      </NuxtLayout>
    </div>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { useStore } from '@/stores/store';

const store = useStore();
const usersStore = useUsersStore();
const { authUserData } = storeToRefs(usersStore);
const { fetchMe } = usersStore;

onMounted(() => {
  fetchMe();
});

// Mobile detection
const headers = useRequestHeaders();
const userAgent = headers?.['user-agent'] ?? navigator.userAgent;
store.userAgent = userAgent;
store.isMobile = userAgent.includes('Mobile') && screen.width <= 992 && window.matchMedia('(orientation: portrait)').matches;
</script>
