export const useStore = defineStore('Store', {
  state: () => {
    return {
      userAgent: '',
      isMobile: false,
      token: null,
    };
  },
  actions: {
  },
  getters: {
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useStore, import.meta.hot));
}
