import { useToast } from 'primevue/usetoast';
import type { NotificationChannel, NotificationType, UnreadNotifications, UserNotificationPreference } from '~/interfaces/notifications/main';

export const useNotificationsStore = defineStore('notifications', () => {
  const toast = useToast();
  const userNotificationPreferences = ref<UserNotificationPreference[]>([]);
  const notificationChannels = ref<NotificationChannel[]>([]);
  const notificationTypes = ref<NotificationType[]>([]);
  const notifications = ref<UnreadNotifications>(null);
  const unreadOnly = ref<boolean>(false);
  const loading = ref<boolean>(false);

  async function getNotifications (unreadOnly = false, limit = null, page = null) {
    const params: any = {
      sortField: 'createdAt',
      sortOrder: 0,
      limit,
      page,
    };

    if (unreadOnly) {
      params['filters[readAt][null][]'] = true;
    }

    const response = await useAuthFetch('notifications', {
      params,
    });

    return response.data.value;
  }

  async function fetchUserNotificationPreferences () {
    userNotificationPreferences.value = [];
    notificationChannels.value = [];
    notificationTypes.value = [];
    loading.value = true;
    try {
      const response = await useAuthFetch('notification-preferences');
      userNotificationPreferences.value = response.data.value.data.userNotificationPreferences;
      notificationChannels.value = response.data.value.data.notificationChannels;
      notificationTypes.value = response.data.value.data.notificationTypes;
    } catch (error) {
      error.value = error;
    } finally {
      loading.value = false;
    }
  }

  async function saveUserNotificationPreferences (notificationPreferences: UserNotificationPreference[]) {
    const data = {
      userNotificationPreferences: notificationPreferences,
    };
    loading.value = true;
    try {
      await useAuthFetch('notification-preferences',
        {
          method: 'POST',
          body: data,
          watch: false,
        }).then((result) => {
        if (result.error.value) {
          toast.add({ severity: 'error', summary: 'Notification Preferences', detail: result.errors[0].title ? result.errors[0].title : 'Les préférences de notification n\'ont pas pu être supprimées' });
        }
      });
    } catch (error) {
      error.value = error;
      toast.add({ severity: 'error', summary: 'Notification Preferences', detail: 'Les préférences de notification n\'ont pas pu être supprimées' });
    } finally {
      loading.value = false;
    }
  }

  return {
    userNotificationPreferences,
    notificationChannels,
    notificationTypes,
    notifications,
    unreadOnly,
    loading,
    getNotifications,
    fetchUserNotificationPreferences,
    saveUserNotificationPreferences,
  };
});
